<template>
  <div>
    <!--begin::Dashboard-->
    <OverallBlocks></OverallBlocks>
    <div class="row">
      <div class="col-xl-8">
        <Cancellations
          v-if="cancellations.length"
          :cancellations="cancellations"
          :total="totalCancellation"
        />
        <SalesChart v-else></SalesChart>
      </div>
      <div class="col-xl-4">
        <RecentReview
          v-show="showReview"
          @show="showReview = true"
          @hide="showReview = false"
        ></RecentReview>
        <BestSellers v-show="!showReview"></BestSellers>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-12">
        <RecentReservations></RecentReservations>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-12">
        <UpcomingEvents></UpcomingEvents>
      </div>
    </div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import OverallBlocks from "@/view/pages/dashboard/components/OverallBlocks.vue";
import SalesChart from "@/view/pages/dashboard/components/SalesChart.vue";
import BestSellers from "@/view/pages/dashboard/components/BestSellers.vue";
import RecentReservations from "@/view/pages/dashboard/components/RecentReservations.vue";
import UpcomingEvents from "@/view/pages/dashboard/components/UpcomingEvents.vue";
import RecentReview from "@/view/pages/dashboard/components/RecentReview.vue";
import Cancellations from "@/view/pages/dashboard/components/Cancellations.vue";

export default {
  name: "dashboard",
  components: {
    OverallBlocks,
    SalesChart,
    BestSellers,
    RecentReservations,
    UpcomingEvents,
    RecentReview,
    Cancellations
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
    this.fetchCancellations();
  },
  data() {
    return {
      cancellations: [],
      totalCancellation: 0,
      showReview: false
    };
  },
  methods: {
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },

    fetchCancellations() {
      ApiService.get("dashboard/cancellations/get.req.php")
        .then(({ data }) => {
          this.cancellations = data.cancellations;
          this.totalCancellation = data.metadata.total;
        })
        .catch(() => {
          this.reservations = [];
        });
    }
  }
};
</script>
