<template>
  <div class="card card-custom gutter-b bg-white card-stretch">
    <!--begin::Header-->
    <div class="card-header h-auto border-0">
      <!--begin::Title-->
      <div class="card-title py-5">
        <h3 class="card-label">
          <span class="d-block text-dark font-weight-bolder"
            >Yaklaşan Turlar</span
          >
        </h3>
      </div>
      <div class="card-toolbar">
        <router-link :to="{ name: 'operations' }" v-slot="{ href }">
          <a
            :href="href"
            class="btn btn-light-primary font-weight-bolder font-size-sm"
          >
            Tüm Operasyonlar
          </a>
        </router-link>
      </div>
      <!--end::Title-->
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <b-table
        class="table table-head-custom table-head-bg table-vertical-center"
        thead-class="mb-10"
        show-empty
        empty-html='
          <div>
            <img src="media/svg/illustrations/list-is-empty-transparent.svg" style="width:40%"/>
            <label class="d-block font-weight-bold font-size-h6 text-muted">Listelenecek herhangi bir tur bulunamadı.</label> 
          </div>'
        stacked="lg"
        :items="events"
        :fields="eventsDataTableFields"
        :current-page="currentPage"
        :per-page="perPage"
        :busy="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle mr-5"></b-spinner>
            <strong>Yükleniyor...</strong>
          </div>
        </template>
        <template #cell(product)="data">
          <div class="py-2 pl-0 max-w-400px">
            <div class="d-flex align-items-start">
              <div class="symbol symbol-60 symbol-2by3 flex-shrink-0 mr-4">
                <div
                  class="symbol-label"
                  :style="{
                    backgroundImage: `url(${data.item.product.image})`
                  }"
                ></div>
              </div>
              <div>
                <span
                  class="
                    font-weight-bolder
                    d-block
                    font-size-md
                  "
                  :class="[
                    { 'text-warning': data.item.product.serviceType === 1 },
                    { 'text-info': data.item.product.serviceType === 2 }
                  ]"
                >
                  <template v-if="data.item.product.serviceType === 1"
                    >Grup</template
                  >
                  <template v-if="data.item.product.serviceType === 2"
                    >Özel</template
                  >
                </span>
                <span
                  class="
                    text-dark-75
                    font-weight-bolder
                    d-block
                    mb-2
                    font-size-lg
                  "
                  >{{ data.item.product.name }}</span
                >
              </div>
            </div>
          </div>
        </template>
        <template #cell(driver)="data">
          <span
            class="
              text-dark-75
              font-weight-bolder
              d-block
              font-size-lg
            "
            >{{ data.item.transportation.company }}</span
          >
          <span
            v-if="data.item.transportation.driver"
            class="
              text-muted
              font-weight-bold
              font-size-lg
            "
            >{{ data.item.transportation.driver }}</span
          >
          <span
            v-if="data.item.transportation.vehicle"
            class="
              text-muted
              font-weight-bold
              font-size-lg
            "
          >
            - {{ data.item.transportation.vehicle }}</span
          >
        </template>
        <template #cell(pnr)="data">
          <router-link
            :to="{ name: 'operation-details', params: { pnr: data.item.pnr } }"
            v-slot="{ href }"
          >
            <a
              :href="href"
              class="
                text-dark-75 text-hover-primary
                font-weight-bolder
                d-block
                font-size-lg
              "
              >{{ data.item.pnr }}</a
            >
          </router-link>
        </template>
        <template #cell(dateTime)="data">
          <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
            {{
              renderDateTime(data.item.departureDateTime, data.item.endDateTime)
            }}
          </span>
        </template>
        <template #cell(pax)="data">
          <span class="text-dark-75 font-weight-bolder d-block font-size-lg"
            >{{ data.item.pax }} Pax</span
          >
        </template>
      </b-table>
      <b-col sm="7" md="6" class="my-1 pl-0" v-if="totalRows > perPage">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          class="my-0"
          size="md"
        >
        </b-pagination>
      </b-col>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import moment from "moment";
import ApiService from "@/core/services/api.service";

export default {
  data() {
    return {
      events: [],
      eventsDataTableFields: [
        { key: "product", label: "Ürün", sortable: false },
        { key: "driver", label: "Şoför", sortable: false },
        { key: "pnr", label: "PNR", sortable: false },
        { key: "dateTime", label: "HAREKET TARİHİ", sortable: false },
        { key: "pax", label: "PAX", sortable: false, class: "text-right" }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      isBusy: false
    };
  },
  mounted() {
    moment.locale("tr");
    this.get();
  },
  methods: {
    get() {
      this.isBusy = true;

      ApiService.get("dashboard/upcomings/get.req.php")
        .then(({ data }) => {
          this.events = data.events;
          this.totalRows = this.events.length;
          setTimeout(() => {
            this.isBusy = false;
            this.$emit("total-rows", this.totalRows);
          }, 500);
        })
        .catch(() => {
          this.events = [];
          this.totalRows = 0;
          setTimeout(() => {
            this.isBusy = false;
          }, 500);
        });
    },
    renderDateTime(departureDate, endDate) {
      let departure = moment(departureDate, "YYYY-MM-DD HH:mm:ss");
      let end = moment(endDate, "YYYY-MM-DD HH:mm:ss");

      if (
        moment(departure.format("YYYY-MM-DD")).isSame(end.format("YYYY-MM-DD"))
      ) {
        return departure.format("D MMM, HH:mm");
      } else {
        if (moment(departure.format("YYYY-MM")).isSame(end.format("YYYY-MM"))) {
          return departure.format("D-") + end.format("D MMM");
        } else {
          return departure.format("D MMM - ") + end.format("D MMM");
        }
      }
    }
  }
};
</script>

<style></style>
