<template>
  <div
    class="card card-custom bg-light gutter-b card-stretch"
    :class="{ 'overlay overlay-block': isBlocking }"
  >
    <!--begin::Body-->
    <div
      class="d-flex flex-column align-items-center justify-content-center h-100"
      v-if="review === null"
    >
      <img
        src="media/svg/illustrations/no-message-transparent.svg"
        style="width:75%"
      />
      <label
        class="d-block font-weight-bold font-size-h6 text-muted text-center py-5"
        >Yakın zamanda yapılan bir değerlendirme yok.</label
      >
    </div>
    <div class="card-body d-flex flex-column" v-else>
      <div class="flex-grow-1 pb-5">
        <!--begin::Info-->
        <div class="d-flex align-items-center pr-2 mb-6">
          <span class="text-muted font-weight-bold font-size-lg flex-grow-1">{{
            review.date
          }}</span>
          <div class="symbol symbol-45">
            <span class="symbol-label bg-light-warning">
              <span class="svg-icon svg-icon-2x svg-icon-warning">
                <inline-svg src="media/svg/icons/Communication/Chat6.svg" />
              </span>
            </span>
          </div>
        </div>
        <!--end::Info-->
        <!--begin::Link-->
        <a
          href="#"
          class="text-dark font-weight-bolder text-hover-primary font-size-h4"
          >{{ review.header }}</a
        >
        <!--end::Link-->
        <span class="d-block" v-html="rating"></span>
        <!--begin::Desc-->
        <p class="text-dark-50 font-weight-normal font-size-lg mt-6">
          {{ review.description }}
        </p>
        <!--end::Desc-->
        <div class="my-6">
          <span class="d-block text-dark-50 font-weight-bold pb-1"
            >{{ review.customer.name }},
            <span class="text-muted font-weight-bold">{{
              review.customer.country
            }}</span></span
          >
          <router-link
            :to="{ name: 'show-experience', params: { id: review.product.id } }"
            v-slot="{ href }"
          >
            <a
              :href="href"
              class="text-dark-50 font-weight-normal text-hover-primary"
              ><u>{{ review.product.title.tr }}</u></a
            >
          </router-link>
        </div>
      </div>
      <!--begin::Team-->
      <div class="d-flex align-items-center">
        <button
          class="btn btn-light-success font-weight-bolder btn-sm mr-3 flex-fill"
          @click="confirm"
        >
          <span class="svg-icon svg-icon-lg">
            <inline-svg src="media/svg/icons/Code/Done-circle.svg" />
          </span>
          Onayla
        </button>
        <button
          class="btn btn-light-danger font-weight-bolder btn-sm flex-fill"
          @click="reject"
        >
          <span class="svg-icon svg-icon-lg">
            <inline-svg src="media/svg/icons/Code/Error-circle.svg" />
          </span>
          Reddet
        </button>
      </div>
      <!--end::Team-->
    </div>
    <!--end::Body-->
    <div class="overlay-layer bg-dark-o-10" v-if="isBlocking">
      <div class="spinner spinner-primary"></div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
  data() {
    return {
      review: null,
      error: {
        status: false,
        message: ""
      },
      isBlocking: false
    };
  },
  computed: {
    rating() {
      let ratingText = "";
      for (let index = 1; index <= this.review.rate; index++) {
        ratingText +=
          '<i class="fa fa-star mr-1 text-warning font-size-lg"></i>';
      }
      for (let index = this.review.rate + 1; index <= 5; index++) {
        ratingText +=
          '<i class="far fa-star mr-1 text-warning font-size-lg"></i>';
      }
      return ratingText;
    }
  },
  mounted() {
    this.get();
  },
  methods: {
    get() {
      ApiService.get("dashboard/recent-review/get.req.php")
        .then(({ data }) => {
          if (data.reviews.length > 0) {
            this.review = data.reviews[0];
            this.$emit("show");
          } else {
            this.review = null;
            this.$emit("hide");
          }
          this.error.status = false;
        })
        .catch(({ response }) => {
          this.error.message = response.data.errorMessage;
          this.error.status = true;
        });
    },

    confirm() {
      this.isBlocking = true;
      const postData = {
        reviewId: this.review.id
      };

      ApiService.post("dashboard/recent-review/confirm.req.php", postData)
        .then(() => {
          setTimeout(() => {
            this.isBlocking = false;
            this.get();
          }, 750);
        })
        .catch(({ response }) => {
          setTimeout(() => {
            this.isBlocking = false;
            alert(response.data.errorMessage);
          }, 750);
        });
    },

    reject() {
      this.isBlocking = true;
      const postData = {
        reviewId: this.review.id
      };

      ApiService.post("dashboard/recent-review/reject.req.php", postData)
        .then(() => {
          setTimeout(() => {
            this.isBlocking = false;
            this.get();
          }, 750);
        })
        .catch(({ response }) => {
          setTimeout(() => {
            this.isBlocking = false;
            alert(response.data.errorMessage);
          }, 750);
        });
    }
  }
};
</script>
