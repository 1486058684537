<template>
  <div
    class="card card-custom gutter-b card-stretch"
  >
    <!--begin::Header-->
    <div class="card-header h-auto border-0">
      <!--begin::Title-->
      <div class="card-title py-5">
        <h3 class="card-label">
          <span class="d-block text-dark font-weight-bolder">Son 12 Aylık Satışlar</span>
        </h3>
      </div>
      <!--end::Title-->
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <Last12MonthSales/>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import Last12MonthSales from "@/view/pages/dashboard/components/Last12MonthSales";

export default {
  components: {
    Last12MonthSales
  }
}
</script>
