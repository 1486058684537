var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-custom gutter-b card-stretch"},[_c('div',{staticClass:"card-header h-auto border-0"},[_vm._m(0),_c('div',{staticClass:"card-toolbar"},[_c('router-link',{attrs:{"to":{ name: 'reservations' }},scopedSlots:_vm._u([{key:"default",fn:function({ href }){return [_c('a',{staticClass:"btn btn-light-primary font-weight-bolder font-size-sm",attrs:{"href":href}},[_vm._v(" Tüm Rezervasyonlar ")])]}}])})],1)]),_c('div',{staticClass:"card-body"},[_c('b-table',{staticClass:"table table-head-custom table-head-bg table-borderless table-vertical-center",attrs:{"thead-class":"mb-10","show-empty":"","empty-html":"\n        <div>\n          <img src=\"media/svg/illustrations/list-is-empty.svg\" style=\"width:30%\"/>\n          <label class=\"d-block font-weight-bold font-size-h6 text-muted\">Listelenecek herhangi bir rezervasyon bulunamadı.</label> \n        </div>","stacked":"lg","items":_vm.reservations,"fields":_vm.reservationsDataTableFields,"current-page":_vm.currentPage,"per-page":_vm.perPage,"busy":_vm.isBusy},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle mr-5"}),_c('strong',[_vm._v("Yükleniyor...")])],1)]},proxy:true},{key:"cell(reservationNumber)",fn:function(data){return [_c('div',{staticClass:"py-2 pl-0 max-w-400px"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-60 symbol-2by3 flex-shrink-0 mr-4"},[_c('div',{staticClass:"symbol-label",style:({
                  backgroundImage: `url(${data.item.product.image})`,
                })})]),_c('div',[_c('span',{staticClass:"text-dark-75 font-weight-bolder d-block mb-2 font-size-lg"},[_vm._v(_vm._s(data.item.product.name))]),_c('div',[_c('span',{staticClass:"text-muted font-weight-bold"},[_vm._v("ZNF-"+_vm._s(data.item.reservationNumber))])])])])])]}},{key:"cell(customer)",fn:function(data){return [_c('span',{staticClass:"text-dark-75 font-weight-bolder d-block font-size-lg"},[_vm._v(_vm._s(data.item.bookedBy))])]}},{key:"cell(dateTime)",fn:function(data){return [_c('span',{staticClass:"text-dark-75 font-weight-bolder d-block font-size-lg"},[_vm._v(" "+_vm._s(_vm.renderDateTime(data.item.departureDateTime, data.item.endDateTime))+" ")])]}},{key:"cell(pax)",fn:function(data){return [_c('span',{staticClass:"text-dark-75 font-weight-bolder d-block font-size-lg"},[_vm._v(_vm._s(data.item.pax)+" Pax")])]}},{key:"cell(price)",fn:function(data){return [_c('span',{staticClass:"text-dark-75 font-weight-bolder font-size-lg pr-1"},[_vm._v(_vm._s(data.item.price.amount))]),_c('span',{staticClass:"text-dark-75 font-weight-bold font-size-lg"},[_vm._v(_vm._s(data.item.price.currency))])]}},{key:"cell(status)",fn:function(data){return [(data.item.status == 1)?_c('span',{staticClass:"label label-lg label-success font-weight-bold label-inline"},[_vm._v("Onaylandı")]):_vm._e(),(data.item.status == 0)?_c('span',{staticClass:"label label-lg label-danger font-weight-bold label-inline"},[_vm._v("İptal Edildi")]):_vm._e()]}},{key:"cell(actions)",fn:function(data){return [_c('router-link',{attrs:{"to":{
            name: 'reservation-details',
            params: {
              id: data.item.id,
              reservationNumber: data.item.reservationNumber,
            },
          }},scopedSlots:_vm._u([{key:"default",fn:function({ href }){return [_c('a',{staticClass:"btn btn-icon btn-light btn-hover-primary btn-sm",attrs:{"href":href}},[_c('span',{staticClass:"svg-icon svg-icon-md svg-icon-primary"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Navigation/Arrow-right.svg"}})],1)])]}}],null,true)})]}}])})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-title py-5"},[_c('h3',{staticClass:"card-label"},[_c('span',{staticClass:"d-block text-dark font-weight-bolder"},[_vm._v("Son Gelen Rezervasyonlar")])])])
}]

export { render, staticRenderFns }