<template>
  <div class="card card-custom gutter-b card-stretch">
    <!--begin::Header-->
    <div class="card-header h-auto border-0">
      <!--begin::Title-->
      <div class="card-title py-5">
        <h3 class="card-label">
          <span class="d-block text-dark font-weight-bolder">İptaller</span>
          <span class="d-block text-muted pt-2 font-size-sm"
            >Toplam {{ total }} adet rezervasyon</span
          >
        </h3>
      </div>
      <!--end::Title-->
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <b-table
        class="table table-head-custom table-head-bg table-borderless table-vertical-center"
        thead-class="mb-10"
        show-empty
        empty-html='
          <div>
            <img src="media/svg/illustrations/list-is-empty.svg" style="width:30%"/>
            <label class="d-block font-weight-bold font-size-h6 text-muted">Listelenecek herhangi bir rezervasyon bulunamadı.</label> 
          </div>'
        stacked="lg"
        :items="cancellations"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :busy="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle mr-5"></b-spinner>
            <strong>Yükleniyor...</strong>
          </div>
        </template>
        <template #cell(reservation)="data">
          <div class="py-2 pl-0 max-w-400px">
            <div class="d-flex align-items-center">
              <div class="symbol symbol-60 symbol-2by3 flex-shrink-0 mr-4">
                <div
                  class="symbol-label"
                  :style="{
                    backgroundImage: `url(${data.item.product.image})`
                  }"
                ></div>
              </div>
              <div>
                <span
                  class="text-dark-75 font-weight-bolder d-block mb-2 font-size-lg"
                  >{{ data.item.product.title }}</span
                >
                <div>
                  <span class="text-muted font-weight-bold"
                    >ZNF-{{ data.item.reservationNumber }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #cell(departureDate)="data">
          <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
            {{
              renderDateTime(data.item.departureDateTime, data.item.endDateTime)
            }}
          </span>
        </template>
        <template #cell(pax)="data">
          <span class="text-dark-75 font-weight-bolder d-block font-size-lg"
            >{{ data.item.pax }} Pax</span
          >
        </template>
        <template #cell(price)="data">
          <span class="text-danger font-weight-bolder font-size-lg pr-1">{{
            data.item.price.value
          }}</span>
          <span class="text-danger font-weight-bold font-size-lg">{{
            data.item.price.currency
          }}</span>
        </template>
        <template #cell(actions)="data">
          <router-link
            :to="{
              name: 'reservation-details',
              params: {
                id: data.item.bookingId,
                reservationNumber: data.item.reservationNumber
              }
            }"
            v-slot="{ href }"
          >
            <a
              :href="href"
              class="btn btn-icon btn-light btn-hover-primary btn-sm"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="media/svg/icons/Navigation/Arrow-right.svg" />
              </span>
            </a>
          </router-link>
        </template>
      </b-table>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    cancellations: {
      type: Array,
      default: () => []
    },
    total: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      fields: [
        { key: "reservation", label: "REZERVASYON", sortable: false },
        { key: "departureDate", label: "HAREKET TARİHİ", sortable: false },
        { key: "pax", label: "PAX", sortable: false, class: "text-right" },
        { key: "price", label: "FİYAT", sortable: false, class: "text-right" },
        { key: "actions", label: "", sortable: false, class: "text-right" }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      isBusy: false
    };
  },
  mounted() {
    moment.locale("tr");
  },
  methods: {
    renderDateTime(departureDate, endDate) {
      let departure = moment(departureDate, "YYYY-MM-DD HH:mm:ss");
      let end = moment(endDate, "YYYY-MM-DD HH:mm:ss");

      if (
        moment(departure.format("YYYY-MM-DD")).isSame(end.format("YYYY-MM-DD"))
      ) {
        return departure.format("D MMM, HH:mm");
      } else {
        if (moment(departure.format("YYYY-MM")).isSame(end.format("YYYY-MM"))) {
          return departure.format("D-") + end.format("D MMM");
        } else {
          return departure.format("D MMM - ") + end.format("D MMM");
        }
      }
    }
  }
};
</script>
